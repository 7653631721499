<template>
	<div>
		<Header></Header>
		<div class="common-header">
			<div class="search-healder">
				<div class="logo-box">
					<img @click="toUrl('/')" class="logo" src="../../image/index/logo.png" />
				</div>
				<div class="search">
					<input placeholder="请输入商品名称" v-model="name" />
					<div class="search-btn el-icon-search" @click="search()"> </div>
				</div>
			</div>
		</div>
		<div class="addr-content">
			<div class="table-bar">
				<el-button type="primary" size="small" @click="add('add')">新增地址</el-button>
			</div>
			<el-table :data="addrs" v-loading="loading" height="500px" border stripe style="width: 100%;">
				<el-table-column type="index" width="50" />
				<el-table-column prop="name" label="收货人" />
				<el-table-column prop="province" label="所在地区">
					<template slot-scope="scope">
						{{scope.row.province}}{{scope.row.city}}{{scope.row.region}}
						<el-tag v-if="scope.row.isDefault==1" type="warning" size="small">默认地址</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="address" label="详细地址" />
				<el-table-column prop="postcode" label="邮编" />
				<el-table-column prop="phone" label="手机号" width="180" />
				<el-table-column label="操作" align="center" width="120">
					<template slot-scope="scope">
						<el-button size="mini" type="text" @click="add('edit',scope)">修改
						</el-button>
						<el-button size="mini" type="text" @click="setDef(scope)" v-if="scope.row.isDefault==0">设为默认
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<Footer></Footer>
		<el-drawer :title="type=='add'?'新增地址':'修改地址'" :visible.sync="open" direction="rtl" size="50%">
			<el-form ref="form" :model="form" label-width="100px" size="small">
				<el-form-item label="收货人姓名" prop="name"
					:rules="[{ required: true, message: '请输入', trigger: ['blur', 'change'] }]">
					<el-input v-model="form.name" autocomplete="off" style="width: 500px;" />
				</el-form-item>
				<el-form-item label="收货电话" prop="phone"
					:rules="[{ required: true, message: '请输入', trigger: ['blur', 'change'] }]">
					<el-input v-model="form.phone" autocomplete="off" style="width: 500px;" />
				</el-form-item>
				<el-form-item label="地址信息" prop="addres"
					:rules="[{ required: true, message: '请选择', trigger: 'change' }]">
					<el-cascader style="width: 500px;" v-model="form.addres" :options="areaOptions" placeholder="请选择"
						:props="{value:'label'}">
						<template #default="{  data }">
							<span>{{ data.label }}</span>
						</template>
					</el-cascader>
				</el-form-item>
				<el-form-item label="详细地址" prop="address"
					:rules="[{ required: true, message: '请输入', trigger: ['blur', 'change'] }]">
					<el-input v-model="form.address" autocomplete="off" style="width: 500px;" />
				</el-form-item>
				<el-form-item label="邮政编码">
					<el-input v-model="form.postcode" autocomplete="off" style="width: 500px;" />
				</el-form-item>
				<el-form-item label="默认地址">
					<el-radio-group v-model="form.isDefault">
						<el-radio :label="1">是</el-radio>
						<el-radio :label="0">否</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item>
					<el-button @click="open = false">取 消</el-button>
					<el-button type="primary" @click="onSubmit('form')" :loading="submitLoading">保 存</el-button>
				</el-form-item>
			</el-form>

		</el-drawer>
	</div>
</template>

<script>
	import {
		addrList,
		addrAdd,
		addrEdit,
		addrDel
	} from '@/api/member/member'
	import areaOptions from "@/utils/shengShiData";
	export default {
		data() {
			return {
				name: '',
				form: {},
				submitLoading: false,
				addrs: [],
				loading: false,
				type: 'add',
				open: false,
				areaOptions: []
			};
		},
		created() {
			this.areaOptions = areaOptions
			this.getAddr()
		},
		methods: {
			search() {
				this.$router.push({
					path: '/item/search?name=' + this.name
				})
			},
			toUrl(url) {
				this.$router.push({
					path: url
				})
			},
			getAddr() {
				this.loading = true
				addrList({}).then(res => {
					this.addrs = res.data
					this.loading = false
				})
			},
			add(type, data) {
				this.type = type
				this.open = true
				if (type == 'add') {
					this.form = {
						name: '',
						phone: '',
						province: '',
						city: '',
						region: '',
						address: '',
						postcode: '',
						isDefault: 0,
						addres: []
					}
				} else {
					this.form = {
						id: data.row.id,
						name: data.row.name,
						phone: data.row.phone,
						province: data.row.province,
						city: data.row.city,
						region: data.row.region,
						address: data.row.address,
						postcode: data.row.postcode,
						isDefault: data.row.isDefault,
						addres: [data.row.province, data.row.city, data.row.region]
					}
				}
			},
			onSubmit(form) {
				var that = this
				if (that.submitLoading) {
					return
				}
				that.submitLoading = true
				that.$refs[form].validate((valid) => {
					if (valid) {
						var data = this.form
						data.province = data.addres[0]
						data.city = data.addres[1]
						data.region = data.addres[2]
						if (this.type == 'add') {
							addrAdd(data).then(res => {
								this.submitLoading = false
								this.open = false
								this.getAddr()
							})
						} else {
							addrEdit(data).then(res => {
								this.submitLoading = false
								this.open = false
								this.getAddr()
							})
						}
					}
				})
			},
			setDef(data) {
				addrEdit({
					id: data.row.id,
					isDefault: 1
				}).then(res => {
					this.getAddr()
				})
			}
		},
	};
</script>

<style lang='scss'>
	.addr-content {
		width: 80%;
		margin-left: 10%;

		.table-bar {
			height: 50px;
			width: 100%;
			margin-top: 20px;
		}
	}
</style>